<template>
  <div>
    <v-select
      :placeholder="$t('eventSchedule.buttonAddTalent')"
      v-if="value.length < max"
      v-model="selectedTag"
      :options="tags.filter(t=> !t.hide)"
      class="mr-3 mb-2"
      @input="onAddTag"
    >
      <span slot="no-options">{{ $t("inputs.emptyDropdown") }}</span>
    </v-select>

    <b-badge
      href="javascript:void(0)"
      variant="primary"
      class="mr-2 py-2 px-3"
      pill
      @click="onRemoveTag(tag)"
      v-for="tag in tags.filter(t=> t.hide)"
      :key="tag.id"
    >
      {{ tag.label }}
      <b-icon icon="trash" />
    </b-badge>
  </div>
</template>

<script>
import EventsTalents from '../../services/EventsTalents';

export default {
  props: ['value'],

  data() {
    return {
      selectedTag: undefined,
      data: [],
    };
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      this.data = await EventsTalents.get();
    },

    onAddTag(selectedTag) {
      const tags = this.tags.map((item) => {
        if (item.value === selectedTag.value) {
          return { ...item, hide: true };
        }

        return item;
      });
      this.selectedTag = null;
      this.$emit('input', tags.filter((t) => t.hide).map((t) => t.value));
    },

    onRemoveTag(selectedTag) {
      const tags = this.tags.map((item) => {
        if (item.value === selectedTag.value) {
          return { ...item, hide: false };
        }

        return item;
      });
      this.selectedTag = null;

      this.$emit('input', tags.filter((t) => t.hide).map((t) => t.value));
    },
  },

  computed: {
    max() {
      return this.$attrs.max || 99999;
    },

    tags() {
      return this.data.map((t) => ({
        value: t.id,
        label: t.name,
        hide: this.value.indexOf(t.id) >= 0,
      })).sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
