<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.schedule') }}
        </h3>

        <router-link :to="{ name: 'events-schedule' }">
          <b-button size="sm" class="ml-3" variant="info">
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col sm="12">
          <b-form-checkbox
            v-model="model.isPublished"
            :value="true"
            :unchecked-value="false"
            class="mt-2"
            switch
          >
            {{ $t('inputs.published') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" class="mb-2">
          <hr />
          <h6 class="text-primary text-decoration-underline text-uppercase">
            {{ $t('eventSchedule.eventInfo') }}
          </h6>
        </b-col>
      </b-row>

      <!-- Nome -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.nameEN')">
            <b-form-input
              v-model="model.nameEN"
              maxlength="150"
              type="text"
              required
            />
          </b-input-group>
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.namePT')">
            <b-form-input
              v-model="model.namePT"
              maxlength="150"
              type="text"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Descricao -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <TextArea
            :label="$t('inputs.descriptionEN')"
            v-model="model.descriptionEN"
            maxlength="500"
          />
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <TextArea
            :label="$t('inputs.descriptionPT')"
            v-model="model.descriptionPT"
            maxlength="500"
          />
        </b-col>
      </b-row>

      <!-- Track -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group prepend="Track">
            <b-form-select v-model="model.track">
              <b-form-select-option value="track1">
                Track 1
              </b-form-select-option>
              <b-form-select-option value="track2">
                Track 2
              </b-form-select-option>
              <b-form-select-option value="track3">
                Track 3
              </b-form-select-option>
            </b-form-select>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Image  -->
      <b-row class="mt-3 mb-4">
        <b-col lg="6" sm="12">
          <b-form-group :label="$t('eventSchedule.image')" class="mb-2">
            <ImageUploader minHeight="780" minWidth="1155" v-model="upload" />
          </b-form-group>
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <img
            :src="upload.preview"
            v-if="upload.preview"
            class="preview mb-1"
            style="max-width: 100%"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <!-- Talentos -->
        <b-col lg="12" sm="12" class="mb-2">
          <hr />

          <h6 class="text-primary text-decoration-underline text-uppercase">
            {{ $t('inputs.talents') }}
          </h6>

          <TalentSelection v-model="model.talent" :max="10" />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <!-- Masterclass -->
        <b-col lg="6" sm="12" class="mb-2">
          <hr />

          <h6 class="text-primary text-decoration-underline text-uppercase">
            {{ $t('inputs.type') }}
          </h6>

          <b-form-checkbox
            v-model="model.isMasterclass"
            :value="true"
            :unchecked-value="false"
            class="mb-2"
            switch
          >
            Masterclass
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col cols="12" class="mt-5 mb-2">
          <hr />

          <div class="d-flex justify-content-between align-items-center">
            <h6
              class="text-primary text-decoration-underline text-uppercase mb-0"
            >
              {{ $t('eventSchedule.calendar') }}
            </h6>
          </div>
        </b-col>

        <b-col sm="12">
          <div class="bg-white border rounded mb-3 p-3">
            <b-row>
              <b-col lg="5" sm="12" class="mb-2">
                <b-input-group :prepend="$t('inputs.stage')">
                  <b-form-select
                    required
                    v-model="model.stage"
                    :options="stageOpts"
                  />
                </b-input-group>
              </b-col>

              <b-col lg="5" sm="12" class="mb-2">
                <b-input-group :prepend="$t('eventSchedule.date')">
                  <b-form-select
                    required
                    v-model="model.dateId"
                    :options="daysOptions"
                  />
                </b-input-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="5" sm="12" class="mb-2">
                <b-input-group :prepend="$t('eventSchedule.startDate')">
                  <datetime
                    type="time"
                    v-model="model.startTime"
                    :minute-step="1"
                    value-zone="America/Sao_Paulo"
                    zone="America/Sao_Paulo"
                    :phrases="{
                      ok: $t('buttons.ok'),
                      cancel: $t('buttons.cancel')
                    }"
                    :title="$t('eventSchedule.startDate')"
                    input-class="form-control"
                    required
                  />
                </b-input-group>
              </b-col>

              <b-col lg="5" sm="12">
                <b-input-group :prepend="$t('eventSchedule.endDate')">
                  <datetime
                    type="time"
                    v-model="model.endTime"
                    :disabled="!model.startTime"
                    :minute-step="1"
                    value-zone="America/Sao_Paulo"
                    zone="America/Sao_Paulo"
                    :min-datetime="model.startTime"
                    :phrases="{
                      ok: $t('buttons.ok'),
                      cancel: $t('buttons.cancel')
                    }"
                    :title="$t('eventSchedule.endDate')"
                    input-class="form-control"
                    required
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="text-right">
          <template v-if="isEdit">
            <b-button
              :disabled="loading"
              variant="danger"
              @click="showDeleteModal = true"
            >
              {{ $t('buttons.delete') }}
            </b-button>

            <span class="mx-2" />
          </template>

          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>

    <b-modal
      centered
      v-model="showDeleteModal"
      @ok="onRemove"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">{{ $t('messages.deleteConfirmMessage') }}</div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Datetime } from 'vue-datetime';
import { DateTime as luxon } from 'luxon';
import Toast from '../../../../assets/js/toast';
import EventsSchedule from '../../../../services/EventsSchedule';
import EventsDates from '../../../../services/EventsDates';
import EventsStage from '../../../../services/EventsStage';
import TalentSelection from '../../../../components/organisms/TalentSelection.vue';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  components: {
    ImageUploader,
    Datetime,
    TalentSelection,
    TextArea,
  },

  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      showDeleteModal: false,

      model: {
        namePT: '',
        nameEN: '',
        descriptionEN: '',
        descriptionPT: '',
        image: '',
        isMasterclass: false,
        isPublished: true,
        talent: [],
        stage: null,
        dateId: '',
        startTime: '',
        endTime: '',
        track: 'track1',
      },

      upload: {
        file: null,
        preview: null,
        blob: null,
      },
      stageList: [],
      daysOptions: [],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.loading = true;

        await this.onLoadEvent();

        const [stages] = await Promise.all([EventsStage.get()]);
        const dates = await EventsDates.get();
        this.daysOptions = this.daysOpts(dates);

        // Masterclass doesnt have live
        this.stageList = stages.filter((s) => !s.isMasterclass).map(
          ({ id: value, name: text, isMasterclass }) => ({
            value,
            text,
            isMasterclass,
          }),
        );

        this.loading = false;
        this.loaded = true;
      } catch (e) {
        Sentry.captureException(e);
        Toast.error(this, e);
      }
    },

    async onLoadEvent() {
      try {
        const { id } = this.$route.params;

        if (id && Number(id)) {
          const xhr = await EventsSchedule.getById(id);

          this.model = {
            ...xhr,
            dateId: xhr.date,
            talent: xhr.talents.map((item) => item.id),
            stage: xhr.stage.id,
          };

          if (xhr.image) {
            this.upload.preview = `${Vue.prototype.VUE_APP_S3_URL}/events/${xhr.image}`;
          }
          this.isEdit = true;
        }

        return true;
      } catch (e) {
        Sentry.captureException(e);
        Toast.error(this, e);
        console.log('Falha ao carregar!', e);

        return false;
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        if (this.upload.file) {
          this.model.image = await this.uploadImage();
        }

        const data = {
          ...this.model,
          date: this.model.dateId,
          startTime: this.formatTime(this.model.startTime),
          endTime: this.formatTime(this.model.endTime),
        };

        const res = await EventsSchedule[action](data, this.model.id);

        if (res.conflict) Toast.warn(this, 'messages.scheduleConflict');
        else Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'events-schedule' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },

    async onRemove() {
      try {
        this.loading = true;

        await EventsSchedule.delete(this.model.id);

        setTimeout(() => {
          this.$router.push({ name: 'events-schedule' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('deleteId', this.model.id);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      }
    },

    async uploadImage() {
      if (!this.upload.file) {
        return this.model.image;
      }

      const fd = new FormData();
      fd.append('file', this.upload.blob, this.upload.file.name);

      const upload = await EventsSchedule.upload(fd, this.model.id);

      if (!upload.filename) {
        Sentry.withScope((scope) => {
          scope.setExtra(
            'errorScheduleUpload',
            JSON.stringify(this.model, undefined, 2),
          );
          Sentry.captureException(new Error('error upload stage image'));
        });
      }

      return upload.filename || this.model.image;
    },

    formatTime(tm) {
      try {
        return luxon
          .fromISO(tm)
          .setLocale(this.$i18n.locale)
          .toLocaleString(luxon.TIME_24_SIMPLE);
      } catch (e) {
        return tm;
      }
    },

    formatDate(date) {
      try {
        return luxon
          .fromISO(date)
          .setLocale(this.$i18n.locale)
          .toLocaleString(luxon.DATE_SHORT);
      } catch (e) {
        Toast.error(e);
        return date;
      }
    },

    daysOpts(dates) {
      return dates.map((date) => ({
        text: this.formatDate(date.date),
        value: date.id,
      }));
    },
  },
  computed: {
    stageOpts() {
      return this.stageList.filter(
        (o) => this.model.isMasterclass === o.isMasterclass,
      );
    },
  },
};
</script>

<style lang="sass">
.vdatetime
  flex-grow: 1
</style>
